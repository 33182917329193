import i18n from 'i18next';

export function getNotificationDescription(code, params = {}) {
  // console.log("getNotificationDescription",code);
  // console.log("getNotificationDescription2asd323", params);
  const t = i18n.t.bind(i18n);

  const notifyCode = {
    SO_R: {
      header: t("Sale Order Released"),
      description: t(code, params)
    },
    SO_C: {
      header: t("Sale Order Created"),
      description: t(code, params)
    },
    SO_F: {
      header: t("Sale Order Failed"),
      description: t(code)
    },
    SO_P: {
      header: t("Sale Order Processed"),
      description: t(code, params)
    },
    SO_S: {
      header: t("Sale Order Shipped"),
      description: t(code, params)
    },
    SO_S_I: {
      header: t("SaleOrder Shipped Initiated"),
      description: t(code, params)
    },
    I_C: {
      header: t("Invoice Created"),
      description: t(code, params)
    },
    I_I: {
      header: t("Invoice Initiated"),
      description: t(code, params)
    },
    C_C: {
      header: t("Credit Memo Created"),
      description: t(code, params)
    },
    C_I: {
      header: t("Creditmemo Initiated"),
      description: t(code, params)
    },
    PS_S: {
      header: t("Payment Status Created"),
      description: t(code, params)
    },
    PD_Sync: {
      header: t("Product Details Synced"),
      description: t(code)
    },
    PP_Sync: {
      header: t("Product Price Synced"),
      description: t(code)
    },
    BPA_C: {
      header: t("Bank Payment Advice Created"),
      description: t(code, params)
    },
    BPA_F: {
      header: t("Bank Payment Advice Failed"),
      description: t(code, params)
    },
    BPA_C_E: {
      header: t("Bank Payment Advic Creation Error"),
      description: t(code, params)
    },
    BPA_F: {
      header: t("Bank Payment Advice Failed"),
      description: t(code, params)
    },
    PS_F: {
      header: t("Payment Status Failed"),
      description: t(code, params)
    },
    CD_Sync: {
      header: t("Contact Details Synced"),
      description: t(code)
    },
    BP_U: {
      header: t("Base Price Uploaded"),
      description: t(code)
    },
    BP_P_U: {
      header: t("Base Price_P Uploaded"),
      description: t(code)
    },
    BP_E: {
      header: t("Base Price Empty"),
      description: t(code)
    },
    BP_F: {
      header: t("Base Price Failed"),
      description: t(code)
    },
    CSP_U: {
      header: t("Customer Specific Price Uploaded"),
      description: t(code)
    },
    CSP_P_U: {
      header: t("Customer Specific Price_P Uploaded"),
      description: t(code)
    },
    CSP_C_U: {
      header: t("Customer Specific Price_C Uploaded"),
      description: t(code)
    },
    CSP_P_C_U: {
      header: t("Customer Specific Price_P_C Uploaded"),
      description: t(code)
    },
    CSP_E: {
      header: t("Customer Specific Price Empty"),
      description: t(code)
    },
    CSP_F: {
      header: t("Customer Specific Price Failed"),
      description: t(code)
    },
    CSP_D_F: {
      header: t("Download Failed"),
      description: t(code)
    },
    CSD_U: {
      header: t("Customer Specific Discount Uploaded"),
      description: t(code)
    },
    CSD_P_U: {
      header: t("Customer Specific Discount_P Uploaded"),
      description: t(code)
    },
    CSD_C_U: {
      header: t("Customer Specific Discount_C Uploaded"),
      description: t(code)
    },
    CSD_P_C_U: {
      header: t("Customer Specific Discount_P_C Uploaded"),
      description: t(code)
    },
    CSD_E: {
      header: t("Customer Specific Discount Empty"),
      description: t(code)
    },
    CSD_D_F: {
      header: t("Download Failed"),
      description: t(code)
    },
    CSD_F: {
      header: t("Customer Specific Discount Failed"),
      description: t(code)
    }
  };

  const status = notifyCode[code];
  // console.log("notifyCode[code]", code)

  if (!status) {
    return {
      header: t("Unknown Status"),
      description: t("Some error occured. Please contact the admin")
    };
  }

  const { header, description } = status;

  return {
    header,
    description: t(description)
  };
}
