import { AddShoppingCart, Favorite, FavoriteBorder, TrendingUpOutlined } from '@mui/icons-material';
import { IconButton, Tooltip, Zoom, useMediaQuery } from '@mui/material'
import { Button } from 'primereact/button';
import React, { useState } from 'react'
import CurrencyFormat from '../../components/CurrencyFormat';
import TruncateText from '../../components/TruncateText';
import Authorizedimages from '../../components/cards/Authorizedimages';
import Loader from '../../components/Loader';
import { useTranslation } from 'react-i18next';
import { Skeleton } from 'primereact/skeleton';




export default function ProductCard({ contextVariable,
    ProductListData, handleAddToCartCount, handleInputChange, handleInputBlur, count, handleDisplayProductDetails,
    addToFavorite, addFavDisable, FavAdded, removeFromFavourite, AddCartRemoveCartUsingProductAdded, isLoading,
    handleUOMDialog, ProductStock, StockUom }) {

    const { t } = useTranslation();
    const getSymbolFromCurrency = require("currency-symbol-map");
    const isTab = useMediaQuery('(max-width: 768px)');
    // const isBetween1024And1440 = useMediaQuery('(min-width:1024px) and (max-width:1440px)');
    const isMobile = useMediaQuery('(min-width:3180x) and (max-width:425px)');

    // const CaptilizeProductName = name => name.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');


    const [overCard, setOverCard] = useState(false);
    const handleOverCard = () => {
        setOverCard(true);
    }
    const handleLeaveCard = () => {
        setOverCard(false);
    }
    const priceBold = 500;
    return (
        <div dir={contextVariable.rtl ? 'rtl' : 'ltr'} className="col-12 md:col-4 lg:col-2" style={{ height: "52vh" }}
            onMouseEnter={handleOverCard} onMouseLeave={handleLeaveCard}
        >
            <div className={`fadein animation-duration-100 card m-1 border-none product-card ${(AddCartRemoveCartUsingProductAdded && contextVariable.UserCartIdDetails &&
                            contextVariable.UserCartIdDetails[0] && contextVariable.UserCartIdDetails[0].CartStatus !== "InProgress") ?
                "bg-addedToCart" : ""}`}
                style={{ height: "51vh" }}
            >
                {isLoading ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "24.5vh",
                        }}
                    >
                        <Loader />
                    </div>
                ) : (
                    <>
                        {(AddCartRemoveCartUsingProductAdded && contextVariable.UserCartIdDetails &&
                            contextVariable.UserCartIdDetails[0] && contextVariable.UserCartIdDetails[0].CartStatus !== "InProgress") && <div className="ribbon">{t('Added')}</div>}
                        {(AddCartRemoveCartUsingProductAdded && contextVariable.UserCartIdDetails &&
                            contextVariable.UserCartIdDetails[0] && contextVariable.UserCartIdDetails[0].CartStatus === "InProgress") && <div className="ribbons">{t('Order Processing')}</div>}


                        {/* product category and favourites */}
                        <div className="flex justify-content-between align-items-center">

                            <div>
                                <span className="p-1 text-xs font-semibold">
                                    <i
                                        className="pi pi-tag mt-1"
                                        style={{
                                            fontSize: "0.7rem",
                                            marginRight: contextVariable.rtl ? "10px" : "5px",
                                            marginLeft: contextVariable.rtl ? "5px" : "0px",
                                        }}
                                    />
                                    {ProductListData.ProductCategoryName}
                                </span>
                            </div>

                            <div>
                                {ProductListData.ProductStatus === "Trending" && (
                                    <Tooltip
                                        componentsProps={{
                                            tooltip: {
                                                sx: {
                                                    bgcolor: 'var(--sidebar-color)',
                                                    fontSize: '12px'
                                                },
                                            },
                                        }}
                                        title="Trending product" placement="bottom" TransitionComponent={Zoom} enterDelay={500}>
                                        <TrendingUpOutlined
                                            className="glowing text-sm"
                                        >
                                        </TrendingUpOutlined>
                                    </Tooltip>
                                )}

                                {
                                    FavAdded &&
                                    <Tooltip
                                        componentsProps={{
                                            tooltip: {
                                                sx: {
                                                    bgcolor: 'var(--sidebar-color)',
                                                    fontSize: '12px'
                                                },
                                            },
                                        }}
                                        title={t('Remove from favourites')}
                                        placement="bottom"
                                        TransitionComponent={Zoom}
                                        enterDelay={500}>
                                        <IconButton onClick={removeFromFavourite} disabled={addFavDisable}>
                                            <Favorite style={{ fontSize: "0.9rem", color: '#D80032' }} />
                                        </IconButton>
                                    </Tooltip>
                                }
                                {
                                    !FavAdded &&
                                    <Tooltip
                                        componentsProps={{
                                            tooltip: {
                                                sx: {
                                                    bgcolor: 'var(--sidebar-color)',
                                                    fontSize: '12px'
                                                },
                                            },
                                        }}
                                        title={t('Add to favourites')}
                                        placement="bottom"
                                        TransitionComponent={Zoom}
                                        enterDelay={500}
                                    >
                                        <IconButton onClick={addToFavorite} disabled={addFavDisable}>
                                            <FavoriteBorder style={{ fontSize: "0.9rem", color: '#D80032' }} />
                                        </IconButton>
                                    </Tooltip>
                                }
                            </div>
                        </div>

                        {/* product image ,price, add to cart  */}
                        <div className="p-1" >
                            {/* Product Image Section*/}
                            <div
                                className="fadein animation-duration-100 flex justify-content-center align-items-center "
                                style={{ height: "20vh" }}
                            >

                                <div onClick={ProductListData.ProductPrice !== null ? handleDisplayProductDetails : null}>
                                    <Authorizedimages ProductImage={ProductListData.ProductImage} From="CatalogPageView" />
                                </div>

                            </div>
                        </div>

                        <div className=' flex flex-column h-full justify-content-center gap-2  '>

                            <div className='flex flex-column '>
                                {/* Product description */}
                                <div>

                                    <div
                                        onClick={handleDisplayProductDetails}
                                        className="pl-2 pr-2   flex  text-ellipsis"
                                        style={{
                                            cursor: "pointer",
                                            color: overCard ? "rgb(21, 102, 149)" : "black",
                                            fontSize: "14px",
                                            lineHeight: "20px",
                                            fontWeight: overCard ? "500" : "400"
                                        }}
                                    >
                                        <TruncateText
                                            // text={CaptilizeProductName(ProductListData.ProductName)}
                                            text={ProductListData.ProductName}
                                            maxLength={isMobile ? 35 : isTab ? 30 : 75}
                                        />
                                    </div>
                                </div>

                                <div>
                                    {/* <PriceAndDiscountSecion ProductListDataID={ProductListData.id} contextVariable={contextVariable} /> */}
                                </div>
                                {/* Product Price */}
                                <div className=''>
                                    {
                                        (ProductListData.ProductPrice !== null && ProductListData.ProductPrice !== 0 && ProductListData.ProductPrice > 0) &&
                                        <div className="pl-2 pr-2">

                                            {/* Product Price Section */}
                                            {ProductListData.ProductsDiscounts.items.length === 0 && (
                                                <div className="w-100">
                                                    <span className=''>
                                                        {getSymbolFromCurrency(
                                                            ProductListData.ProductPriceCurrencyCode
                                                        )}
                                                    </span>
                                                    <span className='text-2xl' style={{ fontWeight: priceBold }}>
                                                        {parseFloat(ProductListData.ProductPrice).toFixed(2)}

                                                    </span>
                                                    <span style={{ color: "var(--sidebar-color)", fontSize: "60%" }}>
                                                        {/* ({ProductListData.ProductUOMText}) */}
                                                        {" "}({parseInt(ProductListData.ProductPriceQuantity)} {contextVariable.AllUOMDetails.find(P =>
                                                            P.Value === ProductListData.ProductPriceQuantityCode
                                                        )?.Description || ''})
                                                    </span>
                                                </div>
                                            )}

                                            {ProductListData.ProductsDiscounts.items.length !== 0 && (
                                                <div className="flex flex-column w-100">
                                                    <div className="">
                                                        <strike style={{ textDecorationColor: 'gray', fontSize: '14px' }}>
                                                            <span style={{ color: 'gray', fontSize: '13px' }}>
                                                                {getSymbolFromCurrency(
                                                                    ProductListData.ProductPriceCurrencyCode
                                                                )}
                                                            </span>
                                                            <span style={{ color: 'gray', fontSize: '13px' }}>
                                                                {ProductListData.ProductPrice}

                                                            </span>
                                                        </strike>

                                                        <span style={contextVariable.isRTL ? { marginRight: '0.2rem', color: '' } : { marginLeft: '0.2rem', color: '' }}>
                                                            {getSymbolFromCurrency(
                                                                ProductListData.ProductPriceCurrencyCode
                                                            )}
                                                        </span>

                                                        <span
                                                            style={{ fontWeight: priceBold }}
                                                            className={ProductListData.ProductPrice.toString().length >= 8 ? 'text-lg ' : 'text-2xl'}
                                                        >
                                                            {CurrencyFormat(
                                                                parseFloat(ProductListData.ProductPrice) -
                                                                parseFloat((Math.abs(parseFloat(ProductListData.ProductsDiscounts.items[0].ProductDiscount)) / 100) *
                                                                    parseFloat(ProductListData.ProductPrice))
                                                            )}

                                                        </span>

                                                        <span style={{ color: "var(--sidebar-color)", fontSize: "60%" }}>
                                                            {/* ({ProductListData.ProductUOMText}) */}
                                                            {" "}({parseInt(ProductListData.ProductPriceQuantity)} {contextVariable.AllUOMDetails.find(P =>
                                                                P.Value === ProductListData.ProductPriceQuantityCode
                                                            )?.Description || ''})
                                                        </span>
                                                    </div>

                                                    <div className="mt-1 d-flex justify-content-start align-items-center gap-2">
                                                        <div className="saved-amount">
                                                            {t('Save')} : {getSymbolFromCurrency(
                                                                ProductListData.ProductPriceCurrencyCode
                                                            )}
                                                            {CurrencyFormat(
                                                                parseFloat((Math.abs(parseFloat(ProductListData.ProductsDiscounts.items[0].ProductDiscount)) / 100) * parseFloat(ProductListData.ProductPrice))
                                                            )}
                                                        </div>
                                                        <span className="discount-badge"
                                                        // style={contextVariable.isRTL ? { marginRight: isTab ? '0rem' : '0.7rem' } : { marginLeft: isTab ? '0rem' : '0.7rem' }}
                                                        >
                                                            {Math.abs(parseFloat(ProductListData.ProductsDiscounts.items[0].ProductDiscount))}%{t('off')}
                                                        </span>
                                                    </div>

                                                </div>
                                            )}

                                        </div>
                                    }
                                </div>



                                {/* Does not contains Product Price */}
                                {
                                    (ProductListData.ProductPrice === null || ProductListData.ProductPrice <= 0) &&
                                    <div className="flex flex-column justify-content-center align-items-center"
                                        style={{ height: "10vh" }}
                                    >
                                        <Tooltip
                                            componentsProps={{
                                                tooltip: {
                                                    sx: {
                                                        bgcolor: 'var(--sidebar-color)',
                                                        fontSize: '12px'
                                                    },
                                                },
                                            }}
                                            title="This product is currently unavailable"
                                            placement="bottom"
                                            TransitionComponent={Zoom}
                                            enterDelay={500}
                                        >
                                            <span className="font-bold text-xs md:text-xs border-round-lg py-1 px-3 bg-gray-100 text-gray-600"
                                            >
                                                {t(`Unavailable`)}
                                            </span>
                                        </Tooltip>
                                    </div>
                                }
                            </div>
                            <div>
                                {/* Inventory details  */}
                                <div>
                                    {
                                        (ProductListData.ProductPrice !== null && ProductListData.ProductPrice !== 0 && ProductListData.ProductPrice > 0 && (contextVariable.taxDetails.InventoryStatus && ProductStock > 0)) &&
                                        <div className="text-xs font-semibold pl-2 pr-2  border-round-sm ">
                                            <span className="text-red-700   border-round-3xl pl-1 pr-1" >{t(`Avl.Stock`)}: {ProductStock} {StockUom}</span>
                                        </div>
                                    }
                                    {
                                        (ProductListData.ProductPrice !== null && ProductListData.ProductPrice !== 0 && ProductListData.ProductPrice > 0 &&
                                            (contextVariable.taxDetails.InventoryStatus && ProductStock === 0)) &&
                                        <div className="text-base font-semibold pl-2 pr-2 pt-4 border-round-sm text-center fadein animation-duration-1000 ">
                                            <span className="text-red-600  border-red-200 p-1 pl-3 pr-3 bg-red-50  border-round-xl " >{t('Out of Stock')}</span>
                                        </div>
                                    }
                                    {
                                        (ProductListData.ProductPrice !== null && ProductListData.ProductPrice !== 0 && ProductListData.ProductPrice > 0 &&
                                            (contextVariable.taxDetails.InventoryStatus && ProductStock === "Loading")) &&
                                        <div className='p-2'>
                                            <Skeleton width="100%" className='mb-2'></Skeleton>
                                            <Skeleton width="50%" ></Skeleton>
                                        </div>

                                    }
                                </div>
                            </div>

                        </div>



                        {/* Add to Cart Section */}
                        <div className=' flex justify-content-center align-items-end  '>

                            {
                                (ProductListData.ProductPrice !== null &&
                                    ProductListData.ProductPrice !== 0 && ProductListData.ProductPrice > 0 &&
                                    (ProductStock === "NoLimit" || ProductStock > 0)) &&
                                <div
                                    className=" flex flex-column justify-content-between w-100  "
                                    style={{ marginRight: contextVariable.rtl ? "9px" : "" }}
                                >


                                    <div className='w-100'>

                                        <Button rounded raised
                                            style={{ borderRadius: "0px 0px 5px 5px" }}
                                            className="primary-button-outlined font-bold w-100  border-none w-100 text-sm"
                                            onClick={handleUOMDialog}
                                            loading={ProductListData.buttonLoading}

                                        >
                                            <span className='w-100'>
                                                {!ProductListData.buttonLoading && <AddShoppingCart ></AddShoppingCart>}
                                                {ProductListData.buttonLoading ? t("Adding") : (AddCartRemoveCartUsingProductAdded && contextVariable.UserCartIdDetails &&
                                                    contextVariable.UserCartIdDetails[0] && contextVariable.UserCartIdDetails[0].CartStatus !== "InProgress") ? t("Add More") : t("Add to cart")}
                                            </span>

                                        </Button>
                                    </div>
                                </div>
                            }
                            {
                                (ProductListData.ProductPrice !== null && ProductListData.ProductPrice !== 0 &&
                                    ProductListData.ProductPrice > 0 && ProductStock === "Loading") &&


                                <div className='w-100'>
                                    <Skeleton width="100%" height='2rem'></Skeleton>
                                </div>

                            }

                        </div>
                    </>
                )}
            </div>
        </div>
    )
}
