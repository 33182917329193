import React, { useContext, useEffect, useState } from 'react';
import { Amplify, API } from "aws-amplify";
import { Auth } from 'aws-amplify';
import awsconfig from '../../aws-exports';
import './Login.css';
import { IconButton, Tab, Tabs, TextField, Tooltip, Zoom, useMediaQuery } from '@mui/material';
import Nipurna from './nipurna.png';
import ProCustomer from "../../logo/ProCustomerPNG.png";
import BackgroundSVG from '../../components/BackgroundSVG';
import Loader from '../../components/Loader';
import LoginIllustration from './LoginIllustration.gif';
import { ArrowBackOutlined } from '@mui/icons-material';
import Timer from '../../components/Timer';
import { showToast } from '../../components/ToastUtils';
import { LoginContext } from '../../App';
import { Button } from 'primereact/button';
import MuiPhoneNumber from 'mui-phone-number';
import { Dialog } from 'primereact/dialog';
import { useTranslation } from 'react-i18next';
import { CreateUserSessionDetail, createUserSessionDetails } from '../../graphql/mutations';
import { tenantUsersByEmail, tenantUsersByPhoneNumber } from '../../Layout/MutaionsAndQueries';
Amplify.configure(awsconfig);

export default function Login() {
    const { t } = useTranslation();
    const contextVariableLogin = useContext(LoginContext);
    const [isSignInClicked, setIsSignInClicked] = useState(false);
    const [loading, setLoading] = useState(false);
    const [otp, setOtp] = useState('');
    const [number, setNumber] = useState('');
    const [email, setEmail] = useState('');
    const [isValidInput, setIsValidInput] = useState('');
    const [ButtonLoading, setButtonLoading] = useState({ SendOtpLoading: false, VerifyOtpLoading: false })
    const [ShowResendOTP, setShowResendOTP] = useState(false);
    const [tabValue, setTabValue] = useState(0);
    const isMobile = useMediaQuery("(max-width:767px)");
    const [tenantList, setTenantList] = useState([]);
    const [selectedTenant, setSelectedTenant] = useState({});
    const [userData, setUserData] = useState(null);
    // const [SendOtpLoading, setSendOtpLoading] = useState(false);
    const [dialogVisible, setDialogVisible] = useState(false);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const validateInput = (input) => {
        // console.log("validate", input);
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phoneRegex = /^\+?\d{10,12}$/;

        const isEmail = emailRegex.test(input);
        const isPhone = phoneRegex.test(input);

        if (isEmail) {
            // console.log("Its email");
            setEmail(prevEmail => input);
            return "email";
        }
        else if (isPhone) {
            // console.log("Its phone");
            setNumber(prevNumber => input);
            return "mobile";
        }
        else {
            return "Invalid Input";
        }
    };

    const handleEmailChange = (event) => {
        // console.log("handleEmailChange", event);
        const inputValue = event.target.value;
        setEmail(inputValue);
        setNumber('');
        setIsValidInput(validateInput(inputValue));
    }

    const handleMobileChange = (value) => {
        // console.log("handleMobileChange", value);
        let trimmedValue = value.replace(/[\s\-(){}]/g, '');
        setNumber(value);
        setEmail('');
        setIsValidInput(validateInput(trimmedValue));
    };

    // const [session, setSession] = useState('');
    const [sessionForOTP, setSessionForOTP] = useState(null);

    useEffect(() => {
        // console.log("first useeffect")
        const handleStorageChange = () => {
            // console.log("INSIDE")
            if (localStorage.getItem('CognitoIdentityServiceProvider') === 'true') {
                window.location.reload();
            }
        };
        window.addEventListener('storage', handleStorageChange);
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
        // eslint-disable-next-line 
    }, [localStorage.getItem('amplify-signin-with-hostedUI')]);

    const toggleDialog = () => {
        setDialogVisible((prev) => !prev);
        setLoading(false);
        setIsSignInClicked(false);
        setButtonLoading((prevLoading) => ({ ...prevLoading, SendOtpLoading: false }))
    }

    const handleCompanySelect = (params) => {
        let tenantDetails = {
            // tenantId: params.tenant + '-' + params.CompanyId,
            tenantId: params.tenant,
            // companyId: params.CompanyId,
            // contactpersonid: params.ContactPersonId,
            // contactpersonUUID: params.ContactPersonUUID,
            // customerid: params.CustomerId,
            // customerUUID: params.CustomerUUID,
            // customerName: params.CustomerName,
            // name: params.ContactName
        }
        // console.log("handleCompanySelect", tenantDetails);
        setSelectedTenant(tenantDetails);
        // setSendOtpLoading(true);
        sendOTP(userData);
        setDialogVisible(false);
    }



    // Step : 2 Fetching user's company details by email
    const fetchTenantUsersByEmail = async (params) => {

        try {
            const response = await API.graphql({
                query: tenantUsersByEmail,
                variables: {
                    Email: params,
                },
                authMode: "AWS_IAM",
            });
            const responseDetails = response.data.tenantUsersByEmail.items.length > 0 ? response.data.tenantUsersByEmail.items[0].TenantMasters.items : [];
            if (responseDetails.length > 0) {
                if (responseDetails.length === 1) {
                    let tenantDetails = {
                        tenantId: responseDetails[0].tenant,
                        // companyId: responseDetails[0].CompanyId,
                        // contactpersonid: responseDetails[0].ContactPersonId,
                        // contactpersonUUID: responseDetails[0].ContactPersonUUID,
                        // customerid: responseDetails[0].CustomerId,
                        // customerUUID: responseDetails[0].CustomerUUID,
                        // customerName: responseDetails[0].CustomerName,
                        // name: responseDetails[0].ContactName
                    }
                    // console.log("tenantDetails", tenantDetails)
                    setSelectedTenant(tenantDetails);
                    setTenantList([]);
                    sendOTP(params);
                }
                else {
                    setTenantList(responseDetails);
                    toggleDialog();
                }
            }
            else {
                // console.error("response error", response);
                showToast(t('User not registered'), 'error', 4000);
                setIsSignInClicked(false);
                setLoading(false);
                setButtonLoading((prevLoading) => ({ ...prevLoading, SendOtpLoading: false }));
            }
        } catch (error) {
            showToast(t('Something went wrong. Please contact the admin.'), 'error', 4000);
            console.error("Error fetchTenantUsersByEmail", error);
            setIsSignInClicked(false);
            setLoading(false);
            setButtonLoading((prevLoading) => ({ ...prevLoading, SendOtpLoading: false }));
        }

    }

    // Step : 2 Fetching user's company details by mobile
    const fetchTenantUsersByMobile = async (params) => {
        try {
            const response = await API.graphql({
                query: tenantUsersByPhoneNumber,
                variables: {
                    PhoneNumber: params,
                },
                authMode: "AWS_IAM",
            });
            const responseDetails = response.data.tenantUsersByPhoneNumber.items.length > 0 ? response.data.tenantUsersByPhoneNumber.items[0].TenantMasters.items : [];

            if (responseDetails.length > 0) {
                if (responseDetails.length === 1) {
                    let tenantDetails = {
                        tenantId: responseDetails[0].tenant,
                        // companyId: responseDetails[0].CompanyId,
                        // contactpersonid: responseDetails[0].ContactPersonId,
                        // contactpersonUUID: responseDetails[0].ContactPersonUUID,
                        // customerid: responseDetails[0].CustomerId,
                        // customerUUID: responseDetails[0].CustomerUUID,
                        // customerName: responseDetails[0].CustomerName,
                        // name: responseDetails[0].ContactName
                    }
                    // console.log("tenantDetails", tenantDetails)
                    setSelectedTenant(tenantDetails);
                    setTenantList([]);
                    sendOTP(params);
                }
                else {
                    setTenantList(responseDetails);
                    toggleDialog();
                }
            }
            else {
                // console.error("response error", response);
                showToast(t('User not registered'), 'error', 4000);
                setIsSignInClicked(false);
                setLoading(false);
                setButtonLoading((prevLoading) => ({ ...prevLoading, SendOtpLoading: false }));
            }
        } catch (error) {
            showToast(t('Something went wrong. Please contact the admin.'), 'error', 4000);
            console.error("Error fetchTenantUsersByEmail", error);
            setIsSignInClicked(false);
            setLoading(false);
            setButtonLoading((prevLoading) => ({ ...prevLoading, SendOtpLoading: false }));
        }
    }

    // Step 5 : Send OTP with selected company details
    const sendOTP = (userData) => {
        // console.log("Inside sendOTP", userData)
        setLoading(true);
        Auth.signIn({
            username: userData,
            // username: "valerie.m@equashield.com",
            // password: "2bf0a032-620c-40d2-9fd0-e7b1273d970d@my347569",
        })
            .then((result) => {
                // contextVariableLogin.setSession(result);
                // alert()
                setSessionForOTP(result)
                setDialogVisible(false);
                setLoading(false);
                // setSendOtpLoading(false);
                // alert(JSON.stringify(SendOtpLoading))
                setIsSignInClicked(true);
                // toggleShowTenantList(tenantList);
                setButtonLoading((prevLoading) => ({ ...prevLoading, SendOtpLoading: false }));
                // console.log("Sigin result", result)
            })
            .catch((e) => {
                setLoading(false);
                setIsSignInClicked(false);
                // setSendOtpLoading(false);
                setButtonLoading((prevLoading) => ({ ...prevLoading, SendOtpLoading: false }))
                if (e.code === 'UserNotFoundException') {
                    // showToast(`This user not found '${e.code}'`, "error");
                    showToast(t('Login failed please contact admin.'), "error", 5000);
                    console.error(e);
                }
                else if (e.code === 'UsernameExistsException') {
                    showToast(t('This user is already exists.'), "error");
                    // signIn();
                }
                else {
                    showToast(t('Mobile number or Email you have entered is invalid. Please check and try again.'), "error");
                    console.error(e);
                }
            });
    }

    // Step 1 : gets tenant list using the email/mobile
    const signIn = (e) => {
        e.preventDefault();
        setButtonLoading((prevLoading) => ({ ...prevLoading, SendOtpLoading: true }))
        setLoading(true);
        setShowResendOTP(false);

        if (isValidInput === "email" || isValidInput === "mobile") {
            let input = isValidInput === "email" ? email : number;
            setUserData(input);
            isValidInput === 'email' ? fetchTenantUsersByEmail(input) : fetchTenantUsersByMobile(input)
            // console.log("userData", userData);
        }
        else {
            setLoading(false);
            setButtonLoading((prevLoading) => ({ ...prevLoading, SendOtpLoading: false }))
            showToast(t(`Invalid Mobile number or Email. Please check and try again.`), "error");
        }
    };

    const createSession = async (params) => {
        // console.log("PARMA", params);
        // console.log("[]]", params["cognito:groups"]);

        let inputData = {};

        inputData = {
            CognitoUserId: params["cognito:username"] || '',
            CompanyId: params["custom:company_id"] || '',
            CompanyName: params["custom:company_Name"] || '',
            ContactName: params.name || '',
            ContactPersonId: params["custom:contactperson_id"] || '',
            ContactPersonUUID: params["custom:contactperson_UUID"] || '',
            CustomerId: params["custom:customer_id"] || '',
            CustomerName: params["custom:customer_Name"] || '',
            CustomerUUID: params["custom:customer_UUID"] || '',
            Email: params.email || '',
            PhoneNumber: params.phone_number || ''
        }

        try {
            // console.log("innputData", inputData)
            const response = await API.graphql({
                query: CreateUserSessionDetail,
                variables: {
                    input: inputData
                }
            })
        } catch (error) {
            console.error("createUserSessionDetails error", error);
            showToast(t('Something went wrong. Please contact the admin.'), 'error', 4000);
        }

    }

    const verifyOtp = async (e) => {
        e.preventDefault();
        setButtonLoading((prevLoading) => ({ ...prevLoading, VerifyOtpLoading: true }));
        try {
            // const challenge = await Auth.sendCustomChallengeAnswer(session, otp);
            // alert(JSON.stringify(sessionForOTP, otp, selectedTenant))
            const challenge = await Auth.sendCustomChallengeAnswer(sessionForOTP, otp, selectedTenant);

            if (challenge.challengeName === 'CUSTOM_CHALLENGE' && !challenge.challengeParam.success) {
                setOtp('');
                showToast(t(`Incorrect OTP. Please try again.`), "error");
                setButtonLoading((prevLoading) => ({ ...prevLoading, VerifyOtpLoading: false }))
            }
            else {
                // setSession(challenge);
                const groups = challenge.signInUserSession.idToken.payload["cognito:groups"];
                // createSession(challenge.signInUserSession.idToken.payload);
                localStorage.setItem("CognitoIdentityServiceProvider", "true");
                contextVariableLogin.setUserGroups(groups[0]);
                setButtonLoading((prevLoading) => ({ ...prevLoading, VerifyOtpLoading: false }))
            }
        }
        catch (err) {
            setButtonLoading((prevLoading) => ({ ...prevLoading, VerifyOtpLoading: false }))
            showToast(t('Something went wrong. Please contact the admin.'), "error");
            setOtp('');
            setNumber('');
            setIsSignInClicked(false);
            setShowResendOTP(false)
            console.error(err);
        }
    };

    const onTimerComplete = () => {
        setShowResendOTP(true)
    }
    const callBackOtpResendFun = (e) => {
        setShowResendOTP(false);
        setOtp('');
        signIn(e);
    }

    const handleOtpChange = (event) => {
        const value = event.target.value;

        if (/^\d{0,4}$/.test(value)) {
            setOtp(value);
            // if (value.length === 4) {
            //     verifyOtp();
            // }
        }
    };


    return (
        <>
            <BackgroundSVG />

            <Dialog
                header="Select Company"
                visible={dialogVisible}
                style={{ width: isMobile ? "90vw" : '50vw' }}
                onHide={() => { if (!dialogVisible) return; setDialogVisible(false); }}>
                <div className='flex justify-content-center align-items-center'>
                    <div className="">
                        {/* Tenant Details list */}
                        {
                            tenantList.map((el, index) => (
                                <div key={index}>
                                    <div
                                        onClick={() => handleCompanySelect(el)}
                                        className="p-2 flex gap-3 justify-content-justify align-items-center align-self-start cursor-pointer hover:bg-blue-50 border-bottom-2 border-gray-100"
                                        style={!isMobile ? { width: '30rem' } : { width: "18rem" }}
                                    >
                                        <div
                                            className="flex justify-content-center p-3 text-lg align-items-center bg-primary text-white border-circle"
                                            style={{ width: '1.8rem', height: '1.8rem' }}>
                                            {el.CompanyName.charAt(0) || '-'}
                                        </div>
                                        <div className="flex flex-column">
                                            <div>
                                                <span className="text-base font-bold" style={{ color: "var(--sidebar-color)" }}>{el.CompanyName || '-'}</span>
                                            </div>
                                            <div className="flex gap-2 text-gray-300 text-sm font-medium">
                                                <div>
                                                    <span>{el.tenant || '-'}</span>
                                                </div>
                                                {/* <div>
                                                    <span>{el.CompanyId || '-'}</span>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </Dialog>

            <section className='container-fluid'>
                <div className='d-flex justify-content-center mt-4 fadein animation-duration-500'>
                    <div className='d-flex gap-2 align-items-center'>
                        <img src={ProCustomer} style={{ height: "80px", width: "280px" }} alt='Nipurna' />
                    </div>
                </div>

                <div className='container mt-1'>
                    <div className='flex gap-8 justify-content-center align-items-center fadein animation-duration-300'>
                        <div className='image-container hide-on-small-screens'>
                            <img src={LoginIllustration} height={'50px'} width={'400px'} alt="login" />
                            {/* <div className='text-center'>
                                <div className='ml-1 mb-1'>
                                    <CheckCircle style={{ fontSize: '14px', marginTop: '-2px' }} sx={{ color: 'green' }} />
                                    <span className='font-semibold text-sm text-gray-600'>
                                        {" "}Secure and convenient payment options.
                                    </span>
                                </div>
                                <div className='mr-5'>
                                    <CheckCircle style={{ fontSize: '14px', marginTop: '-2px' }} sx={{ color: 'green' }} />
                                    <span className='font-semibold text-sm text-gray-600'>
                                        {" "}Easy Installation into your desktop.
                                    </span>
                                </div>
                            </div> */}
                        </div>


                        <div className='login-card fadein animation-duration-300'>
                            <div>
                                <p className="welcome-title">
                                    {t('Welcome!')}<br></br> {t('Shop the Latest Trends Today!')}
                                </p>
                            </div>

                            <>
                                <div className="form-container">
                                    <p className="login-title">Sign In</p>
                                    <form className="form">

                                        {
                                            (!contextVariableLogin.userGroups && !isSignInClicked && !loading) &&
                                            <div className=''>
                                                <Tabs value={tabValue} onChange={handleTabChange}>
                                                    <Tab className='text-base capitalize w-50' label="Mobile" />
                                                    <Tab className='text-base capitalize w-50' label="Email" />
                                                </Tabs>

                                                {tabValue === 0 && (
                                                    <>
                                                        <div className='mt-3'>
                                                            <MuiPhoneNumber
                                                                className='mt-3 mb-2 w-100'
                                                                defaultCountry={'us'}
                                                                onChange={handleMobileChange}
                                                                value={number}
                                                            />
                                                        </div>
                                                    </>
                                                )}
                                                {/* <input
                                                    autoFocus
                                                    type="email"
                                                    className="login-input-email mb-3 w-100"
                                                    placeholder="Enter Email Address"
                                                    name='email'
                                                    onChange={handleEmailChange}
                                                    value={email}
                                                /> */}
                                                {tabValue === 1 && (

                                                    <TextField
                                                        className='mt-5 mb-2 w-100'
                                                        onChange={handleEmailChange}
                                                        value={email}
                                                        variant='standard'
                                                        placeholder='Email'
                                                    />
                                                )}

                                                <Button
                                                    className="mt-4 border-round-3xl d-flex align-items-center justify-content-center w-full"
                                                    loading={ButtonLoading.SendOtpLoading}
                                                    onClick={signIn}
                                                    // onClick={toggleShowTenantList}
                                                    disabled={!email && !number}
                                                >
                                                    <span className='p-1 font-semibold text-base'>Send OTP</span>
                                                </Button>
                                            </div>
                                        }

                                        {/* Verify OTP */}
                                        {
                                            (isSignInClicked && !loading) &&
                                            <>
                                                <div className='flex justify-content-center align-items-center gap-3'>
                                                    <div>
                                                        <Tooltip
                                                            componentsProps={{
                                                                tooltip: {
                                                                    sx: {
                                                                        bgcolor: 'var(--sidebar-color)',
                                                                        fontSize: '12px'
                                                                    },
                                                                },
                                                            }}
                                                            title="Go back"
                                                            placement="bottom"
                                                            TransitionComponent={Zoom}
                                                            enterDelay={300}
                                                        >
                                                            <IconButton
                                                                onClick={() => setIsSignInClicked(false)}
                                                            >
                                                                <ArrowBackOutlined />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </div>
                                                    <div>
                                                        <Timer callBackOtpResendFun={callBackOtpResendFun} onTimerComplete={onTimerComplete} ShowResendOTP={ShowResendOTP} />
                                                    </div>
                                                    <div></div>
                                                </div>

                                                <input
                                                    autoFocus
                                                    type="tel"
                                                    className="otp-input"
                                                    placeholder="Enter 4-digit OTP"
                                                    pattern="[0-9]{4}"
                                                    onChange={(event) => handleOtpChange(event)}
                                                    value={otp}
                                                />

                                                <Button className="mt-3  border-round-3xl d-flex align-items-center justify-content-center "
                                                    loading={ButtonLoading.VerifyOtpLoading}
                                                    disabled={ShowResendOTP}
                                                    onClick={verifyOtp}
                                                >
                                                    <span className='p-1 font-semibold text-base'>Verify</span></Button>
                                            </>
                                        }

                                        {(loading) &&
                                            <div className=" mt-3 flex justify-content-center align-items-center">
                                                <Loader />
                                            </div>
                                        }
                                    </form>
                                </div>
                            </>
                        </div>
                    </div>

                </div>

                {/* Copyrights */}
                <div className='flex justify-content-center'>
                    <footer className="footer flex gap-1">
                        <div className="copyright">
                            &copy; {`${new Date().getFullYear()}`}
                        </div>
                        <img height={"10px"} width={"80px"} src={Nipurna} alt="Nipurna" />.
                        {t('All rights reserved')}.
                    </footer>
                </div>
            </section>

            {/* Wave SVG */}
            {
                !isMobile &&
                <>
                    <svg xmlns="http://www.w3.org/2000/svg"
                        style={{
                            position: 'fixed',
                            bottom: '0',
                            left: '0',
                            zIndex: '-1',
                        }}
                        viewBox="0 0 1440 320">
                        <path fill="#f9aa33"
                            fillOpacity="1"
                            d="M0,128L60,128C120,128,240,128,360,160C480,192,600,256,720,261.3C840,267,960,213,1080,165.3C1200,117,1320,75,1380,53.3L1440,32L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z">
                        </path>
                    </svg>
                </>
            }

        </>
    );
}